import { CreationInputControlsProps } from './common'
import { cls } from '@/utils'
import GSUpload from '@/components/gs-upload'
import KeyframeConditioning from '../keyframe-conditioning'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-small.svg'
import { useAtomValue } from 'jotai'
import { creationInputAtom } from '@/atoms'
import { ModelVersion } from '@/types'

export default function I2vControls({
  expanded,
  keyframeFiles,
  setKeyframeFiles,
  setKeyframeIndicies,
  keyframesUploading,
  setKeyframesUploading,
  enableAfc,
  frameStrengthes,
  setFrameStrengthes,
  setEnableAfc,
  setFile,
  uploading,
  setUploading,
  beforeUpload,
  file,
  textarea,
  settings,
}: CreationInputControlsProps) {
  const showKeyframeConditioning = true
  const showDefaultUpload = !showKeyframeConditioning
  const creationInput = useAtomValue(creationInputAtom)
  const isModel2 = creationInput?.version === ModelVersion.TWO
  const showPlusIconInMiddle = enableAfc || isModel2

  return (
    <fieldset
      className={cls('self-start flex flex-col gap-1 p-3 pt-0 bg-surface w-full shrink-0', expanded ? '' : 'hidden')}
      aria-label='creation-input-fieldset'
    >
      {showDefaultUpload && (
        <div className='flex flex-col items-center gap-3'>
          <GSUpload file={file} fileType='image' uploading={uploading} setUploading={setUploading} onChange={setFile} />
        </div>
      )}
      {showKeyframeConditioning && (
        <>
          <KeyframeConditioning
            files={keyframeFiles}
            setFiles={setKeyframeFiles}
            setFrameIndicies={setKeyframeIndicies}
            frameStrengthes={frameStrengthes}
            setFrameStrengthes={setFrameStrengthes}
            uploading={keyframesUploading}
            setUploading={setKeyframesUploading}
            enableAfc={enableAfc}
            setEnableAfc={setEnableAfc}
            beforeUpload={beforeUpload}
            settings={settings}
            extra={
              <div className='w-full flex flex-col'>
                <div className='w-full grid grid-cols-3 gap-5 md:gap-13 py-1'>
                  <div className='flex items-center justify-center'>
                    {showPlusIconInMiddle ? null : <IconPlus className='size-5 text-icon-subdued' />}
                  </div>
                  <div className='flex items-center justify-center'>
                    {showPlusIconInMiddle ? <IconPlus className='size-5 text-icon-subdued' /> : null}
                  </div>
                </div>
              </div>
            }
          />
          <div className='flex w-full flex-col'>{textarea}</div>
        </>
      )}
    </fieldset>
  )
}
